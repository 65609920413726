import React from "react";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { useState } from "react";
import classes from "./Navigation.module.css";

const DUMMY_NAV_LINKS = [
  { id: "n1", name: "Home", scrollTo: "" },
  { id: "n2", name: "About", scrollTo: "" },
  { id: "n3", name: "Products", scrollTo: "" },
  { id: "n4", name: "Gallery", scrollTo: "" },
  { id: "n5", name: "Contact", scrollTo: "" }
];

// prettier-ignore
const Navigation = ({scrollToHandler, homeEl, sliderEl, aboutEl, productsEl, galleryEl, contactEl}) => {
  const [navigationLinks, setNavigationLinks] = useState(DUMMY_NAV_LINKS)

  return (
    <>
      <header className={classes.header}>
        <div className={classes.container}>
          <a href="/" className={classes.logo__link}>
            <div className={classes.logo}>
              <img
                src={require("../../resources/images/logo-2.png")}
                alt="logo"
                className={classes.logoImg}
              />
            </div>
          </a>
          <MobileNav navigationLinks={navigationLinks} scrollToHandler={scrollToHandler} homeEl={homeEl} sliderEl={sliderEl} aboutEl={aboutEl} productsEl={productsEl} galleryEl={galleryEl} contactEl={contactEl}/>
          <DesktopNav navigationLinks={navigationLinks} scrollToHandler={scrollToHandler} homeEl={homeEl} sliderEl={sliderEl} aboutEl={aboutEl} productsEl={productsEl} galleryEl={galleryEl} contactEl={contactEl}/>
        </div>
      </header>
    </>
  );
};

export default Navigation;

import React from "react";
import { useEffect } from "react";
import { useRef } from "react";

import { SLIDER_IMG_2 as sliderImgs } from "../../store/constants";

import classes from "./Carousel.module.css";

const Carousel = ({ setSliderEl }) => {
  const sliderRef = useRef();

  useEffect(() => {
    setSliderEl(sliderRef.current);
  }, []);

  // prettier-ignore
  // const slides = SLIDER_IMG.map((test, i) => {
  const slides = sliderImgs.map((test, i) => {
        const { id, name, img, } = test;
    
        return (
          <li className={classes.product} key={id}>
            <a>
              <div className={classes.imgBox}>
                <img src={require(`../../assets/${img}`)} alt="img" className={classes.img} />
              </div>
              <p className={classes.name}>{name}</p>
            </a>
          </li>
        );
      });

  // prettier-ignore
  return (
    <section className={classes.section} ref={sliderRef}>
      <div className={classes.container}>
        <div className={classes.testimonialContainer}>
          <ul className={classes.products}>
            {slides[sliderImgs.length - 3]}
            {slides[sliderImgs.length - 2]}
            {slides[sliderImgs.length - 1]}
            {sliderImgs.map((test, i) => {
              const { id, name, img, } = test;
              return (
                <li className={classes.product} key={id}>
                  <a>
                    <div className={classes.imgBox}>
                      <img
                        src={require(`../../assets/${img}`)}
                        alt="img"
                        className={classes.img}
                      />
                    </div>
                    <p className={classes.name}>{name}</p>
                  </a>
                </li>
              );
            })}
            {slides[0]}
            {slides[1]}
            {slides[2]}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Carousel;

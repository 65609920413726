import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import classes from "./Footer.module.css";

const Footer = ({ setContactEl }) => {
  const contactRef = useRef();

  useEffect(() => {
    setContactEl(contactRef.current);
  }, []);

  return (
    <section className={classes.section} ref={contactRef} id="footer">
      <div className={classes.container}>
        <div className={classes.footerContent}>
          <div className={classes.footerEl}>
            <div className={classes.logoName}>
              <img
                src={require("../../resources/images/logo.png")}
                alt="logo"
                className={classes.img}
              />
              <p className={`${classes.name} ${classes.footerText}`}>
                Aura Creativity
              </p>
            </div>
            <div className={classes.addressBox}>
              <p className={`${classes.address} ${classes.footerText}`}>
                <span className={classes.icon}>
                  <i className="fa-solid fa-location-dot"></i>
                </span>
                <span>
                  Shop No. 9, Sai Swarg, Plot No. B-35, Sector 6, New Panvel,
                  District: Raigad, Pin - 410206 Maharashtra, India
                </span>
              </p>
            </div>
          </div>
          <div className={classes.footerEl}>
            <div className={classes.nameSocials}>
              <div className={classes.socialIcons}>
                <span className={classes.socialIcon}>
                  <a style={{ textDecoration: "none", color: "#FFF" }}>
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </span>
                <span className={classes.socialIcon}>
                  <a
                    href="https://www.facebook.com/people/AURA-Creativity/100090162256718/?mibextid=ZbWKwl"
                    target="_blank"
                    style={{ textDecoration: "none", color: "#FFF" }}
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </span>
                <span className={classes.socialIcon}>
                  <a
                    href="https://www.instagram.com/aura_creativity/?igshid=ZGUzMzM3NWJiOQ%3D%3D"
                    target="_blank"
                    style={{ textDecoration: "none", color: "#FFF" }}
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </span>
              </div>
            </div>
            <div className={classes.contactBox}>
              <p className={`${classes.phone} ${classes.footerText}`}>
                <span className={classes.icon}>
                  <i className="fa-solid fa-mobile-screen-button"></i>
                </span>
                <span>9699753573</span>
              </p>
              <p className={`${classes.phone} ${classes.footerText}`}>
                <span className={classes.icon}>
                  <i className="fa-brands fa-whatsapp"></i>
                </span>
                <span>9322653573</span>
              </p>
              <p className={`${classes.website} ${classes.footerText}`}>
                <span className={classes.icon}>
                  <i className="fa-solid fa-link"></i>
                </span>
                <span>www.auracreativity.co.in</span>
              </p>
              <p className={`${classes.email} ${classes.footerText}`}>
                <span className={classes.icon}>
                  <i className="fa-solid fa-envelope"></i>
                </span>
                <span>
                  <a href="mailto:rskurbet@gmail.com">rskurbet@gmail.com</a>
                </span>
              </p>
              <p className={`${classes.email} ${classes.footerText}`}>
                <span className={classes.icon}>
                  <i className="fa-solid fa-envelope"></i>
                </span>
                <span>
                  <a href="mailto:support@auracreativity.co.in">
                    support@auracreativity.co.in
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className={classes.footerEl}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15088.992646281042!2d73.11611!3d19.0087834!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7e84945a9d8eb%3A0x8e140265d5950e5b!2sAura%20Creativity!5e0!3m2!1sen!2sin!4v1683293144594!5m2!1sen!2sin"
              className={classes.map}
              style={{ border: "0px", margin: "0px auto" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import GalleryImg from "./GalleryImg";

import { DUMMY_GALLERY_IMAGES_2 as galleryImgs } from "../../store/constants";
import classes from "./Gallery.module.css";

const Gallery = ({ setGalleryEl, setImgPopupId, openGalleryPopup }) => {
  const galleryRef = useRef();
  const [isContainerLoading, setIsContainerLoading] = useState(true);

  useEffect(() => {
    const section = galleryRef.current;

    const revealSection = (entries, observer) => {
      const [entry] = entries;

      if (!entry.isIntersecting) return;

      setIsContainerLoading(false);
      observer.unobserve(entry.target);
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null,
      threshold: 0,
      rootMargin: "-180px"
    });

    sectionObserver.observe(section);
  }, []);

  useEffect(() => {
    setGalleryEl(galleryRef.current);
  }, []);

  // prettier-ignore
  return (
    <section className={classes.section} ref={galleryRef} id="gallery">
      <div className={`${classes.container} ${isContainerLoading ? classes.hidden : ''}`}>
        <h3 className={classes.sectionHeading}>Gallery</h3>
        <div className={classes.sectionHeadingBorder} />
        <div className={classes.imagesContainer}>
          <ul className={classes.images}>
            {galleryImgs.map((image, i) => {
              const { id, img } = image;
              return (
                <GalleryImg
                  key={id}
                  id={id}
                  img={img}
                  setImgPopupId={setImgPopupId}
                  openGalleryPopup={openGalleryPopup}
                />
              );
            })}            
          </ul>
        </div>
      </div>
    </section>
  );
};
export default Gallery;

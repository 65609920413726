import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

import classes from "./Gallery.module.css";

const GalleryImg = ({ id, img, setImgPopupId, openGalleryPopup }) => {
  const [isContentLoading, setIsContentLoading] = useState(true);

  const contentRef = useRef();

  useEffect(() => {
    const section = contentRef.current;

    const revealSection = (entries, observer) => {
      const [entry] = entries;

      if (!entry.isIntersecting) return;

      setIsContentLoading(false);
      observer.unobserve(entry.target);
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null,
      threshold: 0,
      rootMargin: "-150px"
    });

    sectionObserver.observe(section);
  }, []);

  return (
    <li
      className={`${classes.image} ${isContentLoading ? classes.inActive : ""}`}
      ref={contentRef}
      style={{ padding: "25px" }}
      onClick={() => {
        setImgPopupId(id);
        openGalleryPopup();
      }}
    >
      <div className={classes.imgBox}>
        <img
          src={require(`../../resources/pictures/compressed/${img}`)}
          alt={"name"}
          className={classes.img}
          loading="lazy"
        />
      </div>
    </li>
  );
};

export default GalleryImg;

import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import classes from "./Home.module.css";

const Home = ({ setHomeEl }) => {
  const homeRef = useRef();

  useEffect(() => {
    setHomeEl(homeRef.current);
  }, []);

  return (
    <section className={classes.section} ref={homeRef} id="home">
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <div className={classes.imgContainer}>
            <div className={classes.imgBox}>
              <img
                src={require("../../resources/images/hero-11.png")}
                alt="hero-img"
                className={classes.img}
              />
            </div>
          </div>
          <div className={classes.textContainer}>
            <div className={classes.texts}>
              <h1 className={classes.title}>Aura Creativity</h1>
              <p className={classes.desc}>
                One Stop Solution For Personalized Designing, Branding &
                Printing
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;

import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import classes from "./GoToTopBtn.module.css";

const GoToTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false);

  const gotToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    let heightToHidden = 500;

    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (windowScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);

    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  // prettier-ignore
  return (
    <>
      {isVisible && (
        <button className={classes.btn} onClick={gotToTop}>
          <i className="fa-solid fa-arrow-up"></i>
        </button>
      )}
    </>
  );
};

export default GoToTopBtn;

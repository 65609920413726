import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

import classes from "./Product.module.css";

const Product = ({ name, img }) => {
  const [isContentLoading, setIsContentLoading] = useState(true);

  const contentRef = useRef();

  useEffect(() => {
    const section = contentRef.current;

    const revealSection = (entries, observer) => {
      const [entry] = entries;

      if (!entry.isIntersecting) return;

      setIsContentLoading(false);
      observer.unobserve(entry.target);
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null,
      threshold: 0,
      rootMargin: "-150px"
    });

    sectionObserver.observe(section);
  }, []);

  return (
    <li
      className={`${classes.product} ${
        isContentLoading ? classes.inActive : ""
      }`}
      ref={contentRef}
    >
      <div className={classes.imgBox}>
        <img
          src={require(`../../resources/images/${img}`)}
          alt={name}
          className={classes.img}
        />
      </div>
      <div className={classes.textBox}>
        <p className={classes.text}>{name}</p>
      </div>
    </li>
  );
};

export default Product;

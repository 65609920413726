import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";

import GalleryPopupImg from "./GalleryPopupImg";

import { DUMMY_GALLERY_IMAGES_2 as galleryImgs } from "../../store/constants";

import classes from "./GalleryPopup.module.css";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose}></div>;
};

const ModalOverlay = (props) => {
  const [curIndex, setCurIndex] = useState(
    galleryImgs.findIndex((el, i) => el.id === props.imgPopupId)
  );

  const prevSlideHandler = () => {
    if (curIndex === 0) {
      setCurIndex(galleryImgs.length - 1);
      return;
    }
    setCurIndex((prev) => prev - 1);
  };
  const nextSlideHandler = () => {
    if (curIndex === galleryImgs.length - 1) {
      setCurIndex(0);
      return;
    }
    setCurIndex((prev) => prev + 1);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => (document.body.style.overflow = "auto");
  }, []);

  // prettier-ignore
  return (
      <>
        <div className={classes.galleryContainer}>
          <ul className={classes.images}>
            {[galleryImgs[curIndex]].map((item, i) => {
              const { id, img, } = item;
              return (
                <GalleryPopupImg key={id} img={img} onClose={props.onClose}/>
              );
            })}
          </ul>
        </div>
        <button onClick={prevSlideHandler}
          style={{
            position: "fixed",
            zIndex: 40,
            top: "50%",
            left: 0,
            transform: "translateY(-50%)",
            fontSize: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            height: "40px",
            width: "40px",
            backgroundColor: "rebeccapurple",
            cursor: "pointer"
            }} >
          <i className="fa-solid fa-arrow-left"></i>
        </button>
        <button onClick={nextSlideHandler}
          style={{
            position: "fixed",
            zIndex: 40,
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            fontSize: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            height: "40px",
            width: "40px",
            backgroundColor: "rebeccapurple",
            cursor: "pointer"
            }}>
          <i className="fa-solid fa-arrow-right"></i>
        </button>
      </>
    );
};

const portalElement = document.getElementById("overlay");

const GalleryPopup = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.closeGalleryPopup} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onClose={props.closeGalleryPopup}
          imgPopupId={props.imgPopupId}
        >
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default GalleryPopup;

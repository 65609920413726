import React from "react";
import { useState } from "react";
import ReactDOM from "react-dom";

import classes from "./MobileNav.module.css";

// prettier-ignore
const NavigationLink = ({ id, name, page, isSelected, navigationLinks, setNavigationLinks, setIsNavOpen, scrollToHandler, homeEl, sliderEl, aboutEl, productsEl, galleryEl, contactEl }) => {

  const navigateHandler = (el) => {
    switch (el.toString().toLowerCase()) {
      case "home":
        scrollToHandler(homeEl);
        break;
      case "about":
        scrollToHandler(aboutEl);
        break;
      case "products":
        scrollToHandler(productsEl);
        break;
      case "gallery":
        scrollToHandler(galleryEl);
        break;
      case "contact":
        scrollToHandler(contactEl);
        break;
      default:
        break;
    }
  };

  return (
    // prettier-ignore
    <li className={classes.menuListItem} onClick={() => {
      setIsNavOpen(false);
      navigateHandler(name)
    }}>
      <div>
        <span>{name}</span>
      </div>
    </li>
  );
};

const MobileNav = ({
  navigationLinks,
  scrollToHandler,
  homeEl,
  sliderEl,
  aboutEl,
  productsEl,
  galleryEl,
  contactEl
}) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleHandler = () => {
    const tempArr = navigationLinks.map((el) => ({ ...el, isSelected: false }));
    // setNavigationLinks(tempArr);
    console.log(tempArr);
    setIsNavOpen((prev) => !prev);
  };

  return (
    <>
      {ReactDOM.createPortal(
        // prettier-ignore
        isNavOpen && ( 
          <Overlay setIsNavOpen={setIsNavOpen} navigationLinks={navigationLinks} />
        ),
        document.getElementById("overlay")
      )}
      <nav className={classes.mobileNav}>
        <div
          className={`${
            isNavOpen
              ? `${classes.hamburger} ${classes.active}`
              : `${classes.hamburger}`
          }`}
          onClick={() => toggleHandler()}
        >
          <div className={classes["hamburger__line"]}>
            <div className={classes["hamburger__line--top"]}></div>
            <div className={classes["hamburger__line--middle"]}></div>
            <div className={classes["hamburger__line--bottom"]}></div>
          </div>
        </div>
        <div
          className={`${
            isNavOpen
              ? `${classes.navMenu} ${classes.active}`
              : `${classes.navMenu}`
          }`}
        >
          <div className={classes.menu}>
            <ul className={classes.menuList}>
              {navigationLinks.map((link, i) => {
                const { id, name, page, isSelected } = link;
                return (
                  // prettier-ignore
                  <NavigationLink key={id} id={id} name={name} page={page} isSelected={isSelected} navigationLinks={navigationLinks} setIsNavOpen={setIsNavOpen} scrollToHandler={scrollToHandler} homeEl={homeEl} sliderEl={sliderEl} aboutEl={aboutEl} productsEl={productsEl} galleryEl={galleryEl} contactEl={contactEl} />
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MobileNav;

const Overlay = ({ setIsNavOpen, navigationLinks, setNavigationLinks }) => {
  const toggleHandler = () => setIsNavOpen(false);

  return (
    <div onClick={() => toggleHandler()} className={classes.backdrop}></div>
  );
};

import React from "react";

import classes from "./GalleryPopup.module.css";

const GalleryPopupImg = ({ id, img, onClose }) => {
  return (
    <li className={classes.imageContainer} key={id}>
      <img
        src={require(`../../resources/pictures/compressed/${img}`)}
        alt="img"
        className={classes.img}
      />
      <button className={classes.closeBtn} onClick={onClose}>
        <i className="fa-solid fa-xmark"></i>
      </button>
    </li>
  );
};

export default GalleryPopupImg;

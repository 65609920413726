import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

import Product from "./Product";

import { DUMMY_PRODUCTS as products } from "../../store/constants";

import classes from "./Products.module.css";

const Products = ({ setProductsEl }) => {
  const productsRef = useRef();

  const [isContainerLoading, setIsContainerLoading] = useState(true);

  useEffect(() => {
    const section = productsRef.current;

    const revealSection = (entries, observer) => {
      const [entry] = entries;

      if (!entry.isIntersecting) return;

      setIsContainerLoading(false);
      observer.unobserve(entry.target);
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null,
      threshold: 0,
      rootMargin: "-180px"
    });

    sectionObserver.observe(section);
  }, []);

  useEffect(() => {
    setProductsEl(productsRef.current);
  }, []);

  return (
    <section className={classes.section} ref={productsRef} id="products">
      <div
        className={`${classes.container} ${
          isContainerLoading ? classes.hidden : ""
        }`}
      >
        <h3 className={classes.sectionHeading}>Products</h3>
        <div className={classes.sectionHeadingBorder} />
        <div className={classes.productsContainer}>
          <ul className={classes.products}>
            {products.map((product, i) => {
              const { id, name, img } = product;
              return (
                <Product key={id} name={name} img={img} />
                // <li key={id} className={classes.product}>
                //   <div className={classes.imgBox}>
                //     <img
                //       src={require(`../../resources/images/${img}`)}
                //       alt="name"
                //       className={classes.img}
                //     />
                //   </div>
                //   <div className={classes.textBox}>
                //     <p className={classes.text}>{name}</p>
                //   </div>
                // </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Products;

import React from "react";
import { useState } from "react";
// import TopHeader from "./components/TopHeader/TopHeader";
import Navigation from "./components/Navigation/Navigation";
import Home from "./components/Home/Home";
import Carousel from "./components/Carousel/Carousel";
import About from "./components/About/About";
import Products from "./components/Product/Products";
import Gallery from "./components/Gallery/Gallery";
// import Contact from "./components/Contact/Contact";
import GoToTopBtn from "./components/GoToTop/GoToTopBtn";
import Footer from "./components/Footer/Footer";
import GalleryPopup from "./components/Gallery/GalleryPopup";

// prettier-ignore
function App() {
  const [homeEl, setHomeEl] = useState();
  const [sliderEl, setSliderEl] = useState();
  const [aboutEl, setAboutEl] = useState();
  const [productsEl, setProductsEl] = useState();
  const [galleryEl, setGalleryEl] = useState();
  const [contactEl, setContactEl] = useState();

  const [imgPopupId, setImgPopupId] = useState("");

  const [galleryPopup, setGalleryPopup] = useState(false);

  const openGalleryPopup = () => setGalleryPopup(true);
  const closeGalleryPopup = () => setGalleryPopup(false);


  const scrollToHandler = (section) => {
    console.log(section);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {galleryPopup && <GalleryPopup imgPopupId={imgPopupId} openGalleryPopup={openGalleryPopup} closeGalleryPopup={closeGalleryPopup}/>}
      <Navigation scrollToHandler={scrollToHandler} homeEl={homeEl} sliderEl={sliderEl} aboutEl={aboutEl} productsEl={productsEl} galleryEl={galleryEl} contactEl={contactEl}/>
      <Home setHomeEl={setHomeEl}/>
      <Carousel setSliderEl={setSliderEl}/>
      <About setAboutEl={setAboutEl}/>
      <Products setProductsEl={setProductsEl}/>
      <Gallery setGalleryEl={setGalleryEl} openGalleryPopup={openGalleryPopup} imgPopupId={imgPopupId} setImgPopupId={setImgPopupId} />
      <Footer setContactEl={setContactEl}/>
      <GoToTopBtn />
    </>
  );
}

export default App;

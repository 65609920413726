import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import classes from "./About.module.css";

const About = ({ setAboutEl }) => {
  const aboutRef = useRef();

  const [isContainerLoading, setIsContainerLoading] = useState(true);

  useEffect(() => {
    const section = aboutRef.current;

    const revealSection = (entries, observer) => {
      const [entry] = entries;

      if (!entry.isIntersecting) return;

      setIsContainerLoading(false);
      observer.unobserve(entry.target);
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null,
      threshold: 0,
      rootMargin: "-180px"
    });

    sectionObserver.observe(section);
  }, []);

  useEffect(() => {
    setAboutEl(aboutRef.current);
  }, []);

  return (
    <section className={classes.section} ref={aboutRef} id="about">
      <div
        className={`${classes.container} ${
          isContainerLoading ? classes.hidden : ""
        }`}
      >
        <h3 className={classes.sectionHeading}>About Us</h3>
        <div className={classes.sectionHeadingBorder} />
        <div className={classes.descContainer}>
          <div className={classes.descBox}>
            <p className={classes.descText}>
              Welcome to{" "}
              <span className={classes.highlight}>AURA CREATIVITY</span>.
            </p>
            <p className={classes.descText}>
              Aura Creativity is a one stop source for Designing, Printing &
              Promotional needs in all sectors.
            </p>
            <p className={classes.descText}>
              Aura Creativity started in 2016 with a vision to become a One Stop
              Solution for all your printing needs. With continuous in-house
              developments & a good team, we are capable of satisfying customers
              needs on time with quality & at an economical pricing.
            </p>
            <p className={classes.descText}>
              Our printing facilities covers all requirements for Corporate
              Sectors, Manufacturing Industries, Clinics & Hospitals, Showrooms,
              Service Centres, Schools, Colleges, Educational / Coaching
              Institutes, Event Management Groups, Food Industry & Hotels,
              Gym&apos;s, Clubs & Sports Sector, Construction Groups, Builders,
              Contractors, Shops of all Categories, Shopping Complex & Malls,
              NGO&apos;s, Businessman, Professionals & Freelancer.
              {/* ’ */}
            </p>
            <p className={classes.descText}>
              Printing can be divided in 4 Categories:
              <br />
              Stationery, Fabrics, Advertising & Personalised Printing.
            </p>
          </div>
          <div className={classes.imgBox}>
            <div className={classes.images}>
              <img
                src={require("../../resources/images/slider-img-1.webp")}
                alt="img1"
                className={`${classes.img} ${classes.img1}`}
              />
              <img
                src={require("../../resources/images/slider-img-10.webp")}
                alt="img2"
                className={`${classes.img} ${classes.img2}`}
              />
              <img
                src={require("../../resources/images/slider-img-24.webp")}
                alt="img3"
                className={`${classes.img} ${classes.img3}`}
              />
            </div>
          </div>
        </div>
        <div className={classes.categoriesContainer}>
          <ul className={classes.categories}>
            <li className={classes.category}>
              <div className={classes.categoryTextBox}>
                <p className={classes.categoryText}>
                  Visiting Cards, Letterhead, Bill Book, Files, Registers, Gate
                  Pass, Challan Books, Envelopes, Calendar, Diaries, Planners,
                  Pamphlets, Brochures, Booklets, Photo Prints, Certificates,
                  Labels, Tags, Stickers, Posters for Product Display.
                </p>
                <p className={classes.categoryText}>
                  <span className={classes.categoryTextHighlight}>
                    Packaging Needs:
                  </span>{" "}
                  Paper Bags, Pizza - Cake Box, Sweet Shop Box, Carton -
                  Corrugated Box.
                </p>
                <p className={classes.categoryText}>
                  <span className={classes.categoryTextHighlight}>
                    Food Industry:
                  </span>{" "}
                  Menu Cards, Take-away Cards, Tissue Papers, Parcel Bags, Table
                  Display Standee.
                </p>
                <p className={classes.categoryText}>
                  Ceramic Cups & Plates, Disposable Cups & Glasses.
                </p>
              </div>
              <div className={classes.textBox}>
                <p className={classes.text}>Stationery</p>
              </div>
            </li>
            <li className={classes.category}>
              <div className={classes.categoryTextBox}>
                <p className={classes.categoryText}>
                  <span className={classes.categoryTextHighlight}>
                    For Corporate Clients, Factories & Hospitals Bulk
                    requirements Only:
                  </span>{" "}
                  Flex, Star, Vinyl, One Way Vision, Standee, Sun Board, Glass
                  Film Work, Safety Signs, Glow Sign Boards, Digital & LED
                  Boards, Digital Wall Printing.
                </p>
              </div>
              <div className={classes.textBox}>
                <p className={classes.text}>Indoor & Outdoor Advertising</p>
              </div>
            </li>
            <li className={classes.category}>
              <div className={classes.categoryTextBox}>
                <p className={classes.categoryText}>
                  We do Sublimation Printing, Vinyl Printing, DTF Printing,
                  Patch Printing, Screen Printing suitable for Fabric Related
                  Products such as Round Neck & Collar Polyester T-Shirts,
                  Cotton T-Shirts, Sports Patterns, Track pants, Caps, Hoodies,
                  Aprons, Masks, Handkerchief, Boiler Suits, Uniforms, Schools &
                  Classes Uniforms in bulk quantity. Printing is also done on
                  Bag Products like Jute bags, Cloth bags, Polyester Bags,
                  Laptop Bags, Sports Bags, Paper Bags & Customized Bags.
                </p>
              </div>
              <div className={classes.textBox}>
                <p className={classes.text}>Fabrics</p>
              </div>
            </li>
            <li className={classes.category}>
              <div className={classes.categoryTextBox}>
                <p className={classes.categoryText}>
                  Mugs, Mouse Pads, Keychains, Photo Frames, Clocks, Pillows,
                  Bottles, Coaster, Fridge Magnets, Pens, Badges, Identity Cards
                  & Lanyards, Personalized Gifting Box, Explosion Box, etc.
                </p>
                <p className={classes.categoryText}>
                  <span className={classes.categoryTextHighlight}>
                    Sports & Events:
                  </span>{" "}
                  Trophies, Mementos, Medals, Greetings, Flags
                </p>
              </div>
              <div className={classes.textBox}>
                <p className={classes.text}>Personalised Items for Gifting</p>
              </div>
            </li>
          </ul>
        </div>
        <div className={classes.visitTextBox}>
          <p className={classes.visitText}>
            We know our website can serve as a user-friendly tool in providing
            you the information you need, but it is highly recommended to visit
            our office for more details, samples selection & best printing
            solution. Get connected to Aura Creativity as a Starting Point to
            reach the Finish Line for your Successful Business, Projects &
            Events.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;

/*

Welcome to Aura Creativity.

Aura Creativity is a one stop source for Designing,
Printing & Promotional needs in all sectors.

 

Aura Creativity started in 2016 with a vision to become a
One Stop Solution for all your printing needs. With continuous
in-house developments & a good team, we are capable of satisfying
customers needs on time with quality & at an economical
pricing. Our printing facilities covers all requirements for
Corporate Sectors, Manufacturing Industries, Clinics & Hospitals,
Showrooms, Service Centres, Schools, Colleges,
Educational / Coaching Institutes, Event Management Groups,
Food Industry & Hotels, Gym’s, Clubs & Sports Sector,
Construction Groups, Builders, Contractors, Shops of all Categories,
Shopping Complex & Malls, NGO’s, Businessman, Professionals & Freelancer.

 

Printing can be divided in 4 Categories: 

Stationery, Fabrics, Advertising & Personalised Printing.



Stationery

Visiting Cards, Letterhead, Bill Book, Files, Registers, Gate Pass, Challan Books, Envelopes, Calendar, Diaries, Planners, Pamphlets, Brochures, Booklets, Photo Prints, Certificates, Labels, Tags, Stickers, Posters for Product Display.

Packaging Needs: Paper Bags, Pizza - Cake Box, Sweet Shop Box, Carton - Corrugated Box.

Food Industry: Menu Cards, Take-away Cards, Tissue Papers, Parcel Bags, Table Display Standee

Ceramic Cups & Plates, Disposable Cups & Glasses

 

Fabrics

We do Sublimation Printing, Vinyl Printing, DTF Printing, Patch Printing, Screen Printing suitable for Fabric Related Products such as Round Neck & Collar Polyester T-Shirts, Cotton T-Shirts, Sports Patterns, Track pants, Caps, Hoodies, Aprons, Masks, Handkerchief, Boiler Suits, Uniforms, Schools & Classes Uniforms in bulk quantity. Printing is also done on Bag Products like Jute bags, Cloth bags, Polyester Bags, Laptop Bags, Sports Bags, Paper Bags & Customized Bags.

 

Indoor & Outdoor Advertising

For Corporate Clients, Factories & Hospitals Bulk requirements Only: Flex, Star, Vinyl, One Way Vision, Standee, Sun Board, Glass Film Work, Safety Signs, Glow Sign Boards, Digital & LED Boards, Digital Wall Printing.



Personalised Items for Gifting

Mugs, Mouse Pads, Keychains, Photo Frames, Clocks, Pillows, Bottles, Coaster, Fridge Magnets, Pens, Badges, Identity Cards & Lanyards, Personalized Gifting Box, Explosion Box, etc.

Sports & Events: Trophies, Mementos, Medals, Greetings, Flags

 

Visit our website www.auracreativity.co.in for products & photo gallery. We know our website can serve as a user-friendly tool in providing you the information you need, but it is highly recommended to visit our office for more details, samples selection & best printing solution. Get connected to Aura Creativity as a Starting Point to reach the Finish Line for your Successful Business, Projects & Events.

*/

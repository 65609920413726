// prettier-ignore
export const SLIDER_IMG = [
  {id: 'a1', name: 'Envelopes', img: 'slider-img-19.webp', },
  {id: 'a2', name: 'Visiting Cards', img: 'slider-img-11.webp', },
  {id: 'a3', name: 'Mug Sublimation', img: 'slider-img-20.webp', },
  {id: 'a4', name: 'Calender', img: 'slider-img-13.webp', },
  {id: 'a5', name: 'Letter Head', img: 'slider-img-15.webp', },
  {id: 'a6', name: 'Brochures', img: 'slider-img-16.webp', },
];

// prettier-ignore
export const SLIDER_IMG_2 = [
  {id: 'a1', name: 'Envelopes', img: 'Website 02 product group 13.jpg', },
  {id: 'a2', name: 'Visiting Cards', img: 'Website 02 product group 09.jpg',},
  {id: 'a3', name: 'Mug Sublimation', img: 'Website 02 product group 16.jpg',},
  {id: 'a4', name: 'Calender', img: 'Website 02 product group 14.jpg', },
  {id: 'a5', name: 'Letter Head', img: 'Website 02 product group 10.jpg', },
  {id: 'a6', name: 'Brochures', img: 'Website 02 product group 11.jpg', },
];

export const DUMMY_PRODUCTS = [
  { id: "p1", name: "T-shirt Polyster Sublimation", img: "product-4.webp" },
  { id: "p2", name: "T-shirt Cotton", img: "product-1.webp" },
  { id: "p3", name: "Caps", img: "product-5.webp" },
  { id: "p4", name: "Masks", img: "product-16.webp" },
  { id: "p5", name: "Hoodies", img: "product-72.webp" },
  { id: "p6", name: "Apron", img: "product-70.jpg" },
  { id: "p7", name: "Paper Bags", img: "product-23.webp" },
  { id: "p8", name: "Jute Bags", img: "product-26.webp" },
  { id: "p9", name: "Cotton Bags", img: "product-27.webp" },
  { id: "p10", name: "Sport Bags", img: "product-71.webp" },
  { id: "p11", name: "Visiting Cards", img: "product-35.webp" },
  { id: "p12", name: "Letterheads", img: "product-36.webp" },
  { id: "p13", name: "Bill Book", img: "product-73.jpg" },
  { id: "p14", name: "Brochures", img: "product-74.jpg" },
  { id: "p15", name: "Files", img: "product-75.jpg" },
  { id: "p16", name: "Envelopes", img: "product-45.webp" },
  { id: "p17", name: "Certificates", img: "product-46.webp" },
  { id: "p18", name: "Calender", img: "product-50.webp" },
  { id: "p19", name: "Trophies & Mementos", img: "product-76.jpg" },
  { id: "p20", name: "Mug Sublimation", img: "product-56.webp" },
  { id: "p21", name: "Flex", img: "product-59.webp" },
  { id: "p22", name: "Vinyl", img: "product-77.webp" },
  { id: "p23", name: "One way display", img: "product-78.jpg" },
  { id: "p24", name: "Posters", img: "product-60.webp" },
  { id: "p25", name: "Standee", img: "product-79.webp" },
  { id: "p26", name: "Canopy", img: "product-80.jpg" },
  // { id: "p27", name: "Vehicle Display", img: "product-81.webp",},
  // { id: "p27", name: "Vehicle Display", img: "product-81.jpg",},
  // { id: "p27", name: "Vehicle Display", img: "product-82.jpg",},
  // { id: "p27", name: "Vehicle Display", img: "product-83.webp",},
  // { id: "p27", name: "Vehicle Display", img: "product-84.png",},
  // { id: "p27", name: "Vehicle Display", img: "product-85.jpg",},
  { id: "p27", name: "Vehicle Display", img: "product-86.jpg" },
  { id: "p28", name: "Packaging & Tapes", img: "product-64.webp" },
  { id: "p29", name: "LED Display", img: "product-68.webp" },
  { id: "p30", name: "Digital Wall", img: "digi-wall.jpg" }
];

export const DUMMY_GALLERY_IMAGES_2 = [
  { id: "g86", name: "tshirt", img: "gallery-86.jpg" },
  { id: "g20", name: "tshirt", img: "gallery-20.jpg" },
  { id: "g47", name: "tshirt", img: "gallery-47.jpg" },
  { id: "g6", name: "tshirt", img: "gallery-6.jpg" },
  { id: "g12", name: "tshirt", img: "gallery-12.jpg" },
  { id: "g14", name: "tshirt", img: "gallery-14.jpg" },
  { id: "g15", name: "tshirt", img: "gallery-15.jpg" },
  { id: "g70", name: "tshirt", img: "gallery-70.jpg" },
  { id: "g81", name: "tshirt", img: "gallery-81.jpg" },
  { id: "g79", name: "tshirt", img: "gallery-79.jpg" },
  { id: "g92", name: "tshirt", img: "gallery-92.jpg" },
  { id: "g30", name: "tshirt", img: "gallery-30.jpg" },
  { id: "g31", name: "tshirt", img: "gallery-31.jpg" },
  { id: "g41", name: "tshirt", img: "gallery-41.jpg" },
  { id: "g46", name: "tshirt", img: "gallery-46.jpg" },
  { id: "g74", name: "tshirt", img: "gallery-74.jpg" },
  { id: "g49", name: "tshirt", img: "gallery-49.jpg" },
  { id: "g48", name: "tshirt", img: "gallery-48.jpg" },
  { id: "g43", name: "tshirt", img: "gallery-43.jpg" },
  { id: "g36", name: "tshirt", img: "gallery-36.jpg" },
  { id: "g37", name: "tshirt", img: "gallery-37.jpg" },
  { id: "g38", name: "tshirt", img: "gallery-38.jpg" },
  { id: "g35", name: "tshirt", img: "gallery-35.jpg" },
  { id: "g11", name: "tshirt", img: "gallery-11.jpg" },
  { id: "g44", name: "tshirt", img: "gallery-44.jpg" },
  { id: "g5", name: "tshirt", img: "gallery-5.jpg" },
  { id: "g8", name: "tshirt", img: "gallery-8.jpg" },
  { id: "g9", name: "tshirt", img: "gallery-9.jpg" },
  { id: "g18", name: "tshirt", img: "gallery-18.jpg" },
  { id: "g29", name: "tshirt", img: "gallery-29.jpg" },
  { id: "g32", name: "tshirt", img: "gallery-32.jpg" },
  { id: "g39", name: "tshirt", img: "gallery-39.jpg" },
  { id: "g40", name: "tshirt", img: "gallery-40.jpg" },
  { id: "g50", name: "tshirt", img: "gallery-50.jpg" },
  { id: "g28", name: "shirts", img: "gallery-28.jpg" },
  { id: "g1", name: "caps", img: "gallery-1.jpg" },
  { id: "g4", name: "caps", img: "gallery-4.jpg" },
  { id: "g19", name: "caps", img: "gallery-19.jpg" },
  { id: "g93", name: "caps", img: "gallery-93.jpg" },
  { id: "g7", name: "hoodie", img: "gallery-7.jpg" },
  { id: "g22", name: "masks", img: "gallery-22.jpg" },
  { id: "g23", name: "masks", img: "gallery-23.jpg" },
  { id: "g55", name: "ribbons", img: "gallery-55.jpg" },
  { id: "g91", name: "ribbons", img: "gallery-91.jpg" },
  { id: "g114", name: "ribbons", img: "gallery-114.jpeg" },
  { id: "g59", name: "banners", img: "gallery-59.jpg" },
  { id: "g71", name: "banners", img: "gallery-71.jpg" },
  { id: "g85", name: "banners", img: "gallery-85.jpg" },
  { id: "g24", name: "mugs", img: "gallery-24.jpg" },
  { id: "g25", name: "mugs", img: "gallery-25.jpg" },
  { id: "g26", name: "mugs", img: "gallery-26.jpg" },
  { id: "g27", name: "mugs", img: "gallery-27.jpg" },
  { id: "g53", name: "mugs", img: "gallery-53.jpg" },
  { id: "g54", name: "mugs", img: "gallery-54.jpg" },
  { id: "g76", name: "mugs", img: "gallery-76.jpg" },
  { id: "g109", name: "cottonBags", img: "gallery-109.jpg" },
  { id: "g110", name: "cottonBags", img: "gallery-110.jpeg" },
  { id: "g116", name: "cottonBags", img: "gallery-116.jpg" },
  { id: "g84", name: "cottonBags", img: "gallery-84.jpg" },
  { id: "g95", name: "cottonBags", img: "gallery-95.jpg" },
  { id: "g96", name: "cottonBags", img: "gallery-96.jpg" },
  { id: "g104", name: "bags", img: "gallery-104.jpg" },
  { id: "g105", name: "bags", img: "gallery-105.jpg" },
  { id: "g107", name: "bags", img: "gallery-107.jpg" },
  { id: "g111", name: "visitingCards", img: "gallery-111.jpg" },
  { id: "g113", name: "visitingCards", img: "gallery-113.jpg" },
  { id: "g68", name: "files", img: "gallery-68.jpg" },
  { id: "g94", name: "files", img: "gallery-94.jpg" },
  { id: "g80", name: "files", img: "gallery-80.jpg" },
  { id: "g88", name: "brochures", img: "gallery-88.jpg" },
  { id: "g89", name: "brochures", img: "gallery-89.jpg" },
  { id: "g112", name: "brochures", img: "gallery-112.jpg" },
  { id: "g57", name: "templates", img: "gallery-57.jpg" },
  { id: "g63", name: "templates", img: "gallery-63.jpg" },
  { id: "g65", name: "templates", img: "gallery-65.jpg" },
  { id: "g83", name: "templates", img: "gallery-83.jpg" },
  { id: "g97", name: "templates", img: "gallery-97.jpeg" },
  { id: "g100", name: "templates", img: "gallery-100.jpg" },
  { id: "g101", name: "templates", img: "gallery-101.jpg" },
  { id: "g103", name: "templates", img: "gallery-103.jpg" },
  { id: "g64", name: "templates", img: "gallery-64.jpg" },
  { id: "g60", name: "standees", img: "gallery-60.jpg" },
  { id: "g62", name: "standees", img: "gallery-62.jpg" },
  { id: "g69", name: "standees", img: "gallery-69.jpg" },
  { id: "g75", name: "standees", img: "gallery-75.jpg" },
  { id: "g66", name: "keychains", img: "gallery-66.jpg" },
  { id: "g115", name: "Keychains", img: "gallery-115.jpg" },
  { id: "g102", name: "medals", img: "gallery-102.jpg" },
  { id: "g87", name: "medals", img: "gallery-87.jpg" },
  { id: "g56", name: "trophies", img: "gallery-56.jpg" },
  { id: "g58", name: "ledBox", img: "gallery-58.jpg" },
  { id: "g90", name: "ledBox", img: "gallery-90.jpg" },
  { id: "g73", name: "ledBox", img: "gallery-73.jpg" },
  { id: "g98", name: "vehiclesDisplay", img: "gallery-98.jpg" },
  { id: "g99", name: "vehiclesDisplay", img: "gallery-99.jpg" },
  { id: "g108", name: "vehiclesDisplay", img: "gallery-108.jpg" }
];

/*
export const DUMMY_GALLERY_IMAGES_2 = [
  { id: "g1", name: "", img: "gallery-1.jpg" },
  // { id: "g2", name: "", img: "gallery-2.jpg" },
  // { id: "g3", name: "", img: "gallery-3.jpg" },
  { id: "g4", name: "", img: "gallery-4.jpg" },
  { id: "g5", name: "", img: "gallery-5.jpg" },
  { id: "g6", name: "", img: "gallery-6.jpg" },
  { id: "g7", name: "", img: "gallery-7.jpg" },
  { id: "g8", name: "", img: "gallery-8.jpg" },
  { id: "g9", name: "", img: "gallery-9.jpg" },
  // { id: "g10", name: "", img: "gallery-10.jpg" },
  { id: "g11", name: "", img: "gallery-11.jpg" },
  { id: "g12", name: "", img: "gallery-12.jpg" },
  // { id: "g13", name: "", img: "gallery-13.jpg" },
  { id: "g14", name: "", img: "gallery-14.jpg" },
  { id: "g15", name: "", img: "gallery-15.jpg" },
  // { id: "g16", name: "", img: "gallery-16.jpg" },
  // { id: "g17", name: "", img: "gallery-17.jpg" },
  { id: "g18", name: "", img: "gallery-18.jpg" },
  { id: "g19", name: "", img: "gallery-19.jpg" },
  { id: "g20", name: "", img: "gallery-20.jpg" },
  // { id: "g21", name: "", img: "gallery-21.jpg" },
  { id: "g22", name: "", img: "gallery-22.jpg" },
  { id: "g23", name: "", img: "gallery-23.jpg" },
  { id: "g24", name: "", img: "gallery-24.jpg" },
  { id: "g25", name: "", img: "gallery-25.jpg" },
  { id: "g26", name: "", img: "gallery-26.jpg" },
  { id: "g27", name: "", img: "gallery-27.jpg" },
  { id: "g28", name: "", img: "gallery-28.jpg" },
  { id: "g29", name: "", img: "gallery-29.jpg" },
  { id: "g30", name: "", img: "gallery-30.jpg" },
  { id: "g31", name: "", img: "gallery-31.jpg" },
  { id: "g32", name: "", img: "gallery-32.jpg" },
  // { id: "g33", name: "", img: "gallery-33.jpg" },
  // { id: "g34", name: "", img: "gallery-34.jpg" },
  { id: "g35", name: "", img: "gallery-35.jpg" },
  { id: "g36", name: "", img: "gallery-36.jpg" },
  { id: "g37", name: "", img: "gallery-37.jpg" },
  { id: "g38", name: "", img: "gallery-38.jpg" },
  { id: "g39", name: "", img: "gallery-39.jpg" },
  { id: "g40", name: "", img: "gallery-40.jpg" },
  { id: "g41", name: "", img: "gallery-41.jpg" },
  // { id: "g42", name: "", img: "gallery-42.jpg" },
  { id: "g43", name: "", img: "gallery-43.jpg" },
  { id: "g44", name: "", img: "gallery-44.jpg" },
  // { id: "g45", name: "", img: "gallery-45.jpg" },
  { id: "g46", name: "", img: "gallery-46.jpg" },
  { id: "g47", name: "", img: "gallery-47.jpg" },
  { id: "g48", name: "", img: "gallery-48.jpg" },
  { id: "g49", name: "", img: "gallery-49.jpg" },
  { id: "g50", name: "", img: "gallery-50.jpg" },
  // { id: "g51", name: "", img: "gallery-51.jpg" },
  // { id: "g52", name: "", img: "gallery-52.jpg" },
  { id: "g53", name: "", img: "gallery-53.jpg" },
  { id: "g54", name: "", img: "gallery-54.jpg" },
  { id: "g55", name: "", img: "gallery-55.jpg" },
  { id: "g56", name: "", img: "gallery-56.jpg" },
  { id: "g57", name: "", img: "gallery-57.jpg" },
  { id: "g58", name: "", img: "gallery-58.jpg" },
  { id: "g59", name: "", img: "gallery-59.jpg" },
  { id: "g60", name: "", img: "gallery-60.jpg" },
  // { id: "g61", name: "", img: "gallery-61.jpg" },
  { id: "g62", name: "", img: "gallery-62.jpg" },
  { id: "g63", name: "", img: "gallery-63.jpg" },
  { id: "g64", name: "", img: "gallery-64.jpg" },
  { id: "g65", name: "", img: "gallery-65.jpg" },
  { id: "g66", name: "", img: "gallery-66.jpg" },
  // { id: "g67", name: "", img: "gallery-67.jpg" },
  { id: "g68", name: "", img: "gallery-68.jpg" },
  { id: "g69", name: "", img: "gallery-69.jpg" },
  { id: "g70", name: "", img: "gallery-70.jpg" },
  { id: "g71", name: "", img: "gallery-71.jpg" },
  // { id: "g72", name: "", img: "gallery-72.jpg" },
  { id: "g73", name: "", img: "gallery-73.jpg" },
  { id: "g74", name: "", img: "gallery-74.jpg" },
  { id: "g75", name: "", img: "gallery-75.jpg" },
  { id: "g76", name: "", img: "gallery-76.jpg" },
  //   { id: "g77", name: "", img: "gallery-77.jpg" },
  // { id: "g78", name: "", img: "gallery-78.jpg" },
  { id: "g79", name: "", img: "gallery-79.jpg" },
  { id: "g80", name: "", img: "gallery-80.jpg" },
  { id: "g81", name: "", img: "gallery-81.jpg" },
  // { id: "g82", name: "", img: "gallery-82.jpg" },
  { id: "g83", name: "", img: "gallery-83.jpg" },
  { id: "g84", name: "", img: "gallery-84.jpg" },
  { id: "g85", name: "", img: "gallery-85.jpg" },
  { id: "g86", name: "", img: "gallery-86.jpg" },
  { id: "g87", name: "", img: "gallery-87.jpg" },
  { id: "g88", name: "", img: "gallery-88.jpg" },
  { id: "g89", name: "", img: "gallery-99.jpg" },
  { id: "g90", name: "", img: "gallery-90.jpg" },
  { id: "g91", name: "", img: "gallery-91.jpg" },
  { id: "g92", name: "", img: "gallery-92.jpg" },
  { id: "g93", name: "", img: "gallery-93.jpg" },
  { id: "g94", name: "", img: "gallery-94.jpg" },
  { id: "g95", name: "", img: "gallery-95.jpg" },
  { id: "g96", name: "", img: "gallery-96.jpg" },
  { id: "g97", name: "", img: "gallery-97.jpeg" },
  { id: "g98", name: "", img: "gallery-98.jpg" },
  { id: "g99", name: "", img: "gallery-99.jpg" },
  { id: "g100", name: "", img: "gallery-100.jpg" },
  { id: "g101", name: "", img: "gallery-101.jpg" },
  { id: "g102", name: "", img: "gallery-102.jpg" },
  { id: "g103", name: "", img: "gallery-103.jpg" },
  { id: "g104", name: "", img: "gallery-104.jpg" },
  { id: "g105", name: "", img: "gallery-105.jpg" },
  // { id: "g106", name: "", img: "gallery-106.jpg" },
  { id: "g107", name: "", img: "gallery-107.jpg" },
  { id: "g108", name: "", img: "gallery-108.jpg" },
  { id: "g109", name: "", img: "gallery-109.jpg" },
  { id: "g110", name: "", img: "gallery-110.jpeg" },
  { id: "g111", name: "", img: "gallery-111.jpg" },
  { id: "g112", name: "", img: "gallery-112.jpg" },
  { id: "g113", name: "", img: "gallery-113.jpg" },
  { id: "g114", name: "", img: "gallery-114.jpeg" },
  { id: "g115", name: "", img: "gallery-115.jpg" },
  { id: "g116", name: "", img: "gallery-116.jpg" }
];

*/

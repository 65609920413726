import React from "react";
import classes from "./DesktopNav.module.css";

// prettier-ignore
const NavLinks = ({ name, menu, page, scrollToHandler, homeEl, sliderEl, aboutEl, productsEl, galleryEl, contactEl}) => {
  
  const navigateHandler = (el) => {
    switch (el.toString().toLowerCase()) {
      case "home":
        scrollToHandler(homeEl);
        break;
      case "about":
        scrollToHandler(aboutEl);
        break;
      case "products":
        scrollToHandler(productsEl);
        break;
      case "gallery":
        scrollToHandler(galleryEl);
        break;
      case "contact":
        scrollToHandler(contactEl);
        break;
      default:
        break;
    }
  };

  return (
    <li
      className={classes["navLinks__list--item"]}
      onClick={() => navigateHandler(name)}
    >
      <button type="button" className={classes.linkBtn}>
        <span className={classes.linkTxtSpan}>
          <p className={classes.linkTxt}>{name}</p>
        </span>
      </button>
    </li>
  );
};

// prettier-ignore
const DesktopNav = ({ navigationLinks, scrollToHandler, homeEl, sliderEl, aboutEl, productsEl, galleryEl, contactEl }) => {
  return (
    <nav className={classes.navLinks}>
      <ul className={classes.navLinks__list}>
        {navigationLinks.map((link, i) => {
          const { id, name, menu, page } = link;
          return <NavLinks key={id} name={name} menu={menu} page={page} scrollToHandler={scrollToHandler} homeEl={homeEl} sliderEl={sliderEl} aboutEl={aboutEl} productsEl={productsEl} galleryEl={galleryEl} contactEl={contactEl}/>;
        })}
      </ul>
    </nav>
  );
};

export default DesktopNav;
